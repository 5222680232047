import throttle from 'lodash/throttle'

export default class ObserverHelper {
  constructor (throttleTime) {
    this.callbacks = []

    this.throttleTime = throttleTime

    this.subscribe = this.subscribe.bind(this)
    this.unsubscribe = this.unsubscribe.bind(this)
    this.publish = this.publish.bind(this)
    this.reset = this.reset.bind(this)

    if (this.throttleTime) {
      this.publish = throttle(this.publish, this.throttleTime)
    }
  }

  subscribe (callback) {
    this.callbacks.push(callback)
  }

  unsubscribe (callbackToRemove) {
    this.callbacks = this.callbacks.filter(
      callback => callback !== callbackToRemove
    )
  }

  publish (event) {
    this.callbacks.forEach((callback) => {
      try {
        callback(event)
      } catch (error) {
        console.error(
          'ObserverHelper - fail to execute callback on publish value.\n',
          'Callback:', callback, '\n',
          'Event: ', event, '\n',
          error
        )
      }
    })
  }

  reset () {
    this.callbacks = []
  }
}
