import axios from '../resources/axios'

const environment = process.env.NODE_ENV

let logCounter = 0
let errorCounter = 0
let fatalCounter = 0
let warnCounter = 0

export const vLogger = {
  install (Vue) {
    Vue.prototype.$log = log
    Vue.prototype.$warn = warn
    Vue.prototype.$error = error
    Vue.prototype.$fatal = fatal
  }
}

const external = (type, message, additional) => {
  const msg = JSON.stringify(message)
  const line = message.substring(0, 110)

  let ad = null
  if (additional) {
    ad = JSON.stringify(additional).substring(0, 510) + '.' // so it doesnt get parsed
  }

  const payload = {
    lines: [
      {
        line,
        app: 'spectator',
        level: type,
        env: environment,
        timestamp: Date.now(),
        meta: {
          message: msg,
          detail: ad ? ad.toString() : 'empty'
        }
      }
    ]
  }

  return axios.logger.post(payload).catch((e) => {
    console.error('cant log: ' + e)
  })
}

/**
* Logger constructor
* @param  {String} type - Type of the log message
* @param  {String} message - Your log text
* @param  {Object} _object - If you pass an object or array, it's will be put together
* @return {Function}
*/
const logControll = (type, message, _object) => {
  try {
    const object = _object !== undefined
      ? _object
      : ''

    const messageMaker = (counter, message) => `${logCounter} [${type}|Meeting Recorder] ${JSON.stringify(message)}`

    external(type, messageMaker(logCounter, message), object)

    if (environment !== 'production') {
      switch (type) {
        case 'LOG':
          logCounter++
          return console.log(
            messageMaker(logCounter, message),
            object
          )

        case 'WARN':
          warnCounter++
          return console.warn(
            messageMaker(warnCounter, message),
            object
          )

        case 'ERROR':
          errorCounter++
          return console.error(
            messageMaker(errorCounter, message),
            object
          )

        case 'FATAL':
          fatalCounter++
          return console.error(
            messageMaker(fatalCounter, message),
            object
          )

        case 'INTERCEPT':
          return console.warn(
            messageMaker(0, message),
            object
          )

        default:
          return console.log(message, object)
      }
    }
  } catch (e) {
    console.error('cant log', e)
  }
}

/**
* Add conter and prefix and execute simple console.log()
* @param  {String} message - Your log text
* @param  {Object} object - If you pass an object or array, it's will be put together
* @return {Function}
*/
export const log = (message, object) =>
  logControll('LOG', message, object)

/**
* Add conter and prefix and execute console.warn()
* @param  {String} message - Your log text
* @param  {Object} object - If you pass an object or array, it's will be put together
* @return {Function}
*/
export const warn = (message, object) =>
  logControll('WARN', message, object)

/**
* Add conter and prefix and execute console.error()
* @param  {String} message - Your log text
* @param  {Object} object - If you pass an object or array, it's will be put together
* @return {Function}
*/
export const error = (message, object) =>
  logControll('ERROR', message, object)

/**
* Add conter and prefix and create a throw new Error()
* @param  {String} message - Your log text
* @return {Function}
*/
export const fatal = message =>
  logControll('FATAL', message)

/**
* Used to intercept error console logs
* @param  {String} message - Your log text
* @return {Function}
*/
export const intercept = message =>
  logControll('INTERCEPT', message)

/**
* Used to intercept error console logs
* @param  {String} message - Your log text
* @return {Function}
*/
export const version = message =>
  logControll('VERSION', message)
