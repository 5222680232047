/*
 * Defines the possible Meeting connection states.
 * NOT_AVAILABLE: Chime is disconnected (probably..);
 * GOOD: Good connection;
 * BAD: Bad connection. Turn off video is recommended;
 * POOR: Poor connection. User connection and/or PC not meet the minimum requirements;
 * DISCONNECTED: Chime is not able to send/receive network packets for at least 10 secs;
 * RECONNECTING: Reconnecting due to loss of connection.
 */
const STATUS = {
  NOT_AVAILABLE: 0,
  GOOD: 1,
  BAD: 2,
  POOR: 3,
  DISCONNECTED: 4,
  RECONNECTING: 5
}

export default {
  STATUS
}
