<template>
  <div class="sv-recorder-container sv-color-black-background">
    <VideoTile
      v-for="tileId, index in activeTiles"
      :key="tileId"
      v-show="index === 0"
      :tile-id="tileId"
    />
    <audio ref="audioElement" />
  </div>
</template>

<script>
import VideoTile from '@/components/VideoTile'

import MeetingSpectatorService from '@/services/MeetingSpectatorService'

export default {
  name: 'Home',
  components: {
    VideoTile
  },
  data: () => ({
    activeTiles: [],
    meetingSession: null
  }),
  async mounted () {
    await MeetingSpectatorService.startSpectator({
      title: this.$route.params.meetingId,
      name: this.$route.params.meetingId,
      userId: this.$route.params.userId,
      audioElement: this.$refs.audioElement
    })

    MeetingSpectatorService.subscribeToActiveVideoTile(this.onActiveTileDidChange)
  },
  beforeDestroy () {
    MeetingSpectatorService.unsubscribeFromActiveVideoTile(this.onActiveTileDidChange)
  },
  methods: {
    onActiveTileDidChange (activeTiles) {
      this.activeTiles = activeTiles
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@SuperViz/sv-components/index.scss"

.sv-recorder-container
  @include absolute(0, 0, 0, 0)

  display: flex
  align-items: center
  justify-content: center
</style>
