import http from '../http'

export default ({ meetingId }) => {
  const headers = {
    'Content-Type': 'application/json',
    'Secret-Key': process.env.VUE_APP_API_KEY
  }

  return http.post(`${process.env.VUE_APP_API_BASE_URL}/recordings/stop`, { meeting_id: meetingId }, { headers })
    .then(response => response.data)
}
