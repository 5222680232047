/*
 * Defines the possible Meeting states.
 * DISCONNECTED: Not connected to meeting room and not starting a session;
 * INITIALIZING: startMeeting is creating room session and initializing default devices;
 * READY_TO_JOIN: Room session created and devices initialized. User is now ready to join meeting room;
 * CONNECTED: User is 'inside' a meeting room, he is now able to see, listen and talk with other attendees;
 * FAILED: Something went wrong
 */
export const MEETING_STATE = {
  DISCONNECTED: 0,
  INITIALIZING: 1,
  CONNECTING: 3,
  CONNECTED: 4,
  FAILED: -1,
  RETRYING: -2
}

export const MEETING_FAILED_REASONS = {
  UNKNOWN: 1,
  BROWSER_NOT_SUPPORTED: 2,
  DEVICE_INITIALIZATION: 3
}

export default {
  MEETING_STATE,
  MEETING_FAILED_REASONS
}
