import { LogLevel } from 'amazon-chime-sdk-js'

import * as logger from './logger'

const AdaptedLogLevel = {
  ...LogLevel,
  ...(LogLevel.FATAL ? {} : { FATAL: 10 }),
  ...(LogLevel.IMPORTANT ? {} : { IMPORTANT: 100 })
}

export default class MeetingServiceLogger {
  name
  level
  logger = logger

  constructor (name, level = AdaptedLogLevel.WARN) {
    this.name = name
    this.level = level
  }

  info (msg) {
    this.log(AdaptedLogLevel.INFO, msg)
  }

  warn (msg) {
    this.log(AdaptedLogLevel.WARN, msg)
  }

  error (msg) {
    this.log(AdaptedLogLevel.ERROR, msg)
  }

  fatal (msg) {
    this.log(AdaptedLogLevel.FATAL, msg)
  }

  debug (debugFunction) {
    if (AdaptedLogLevel.DEBUG < this.level) {
      return
    }

    if (typeof debugFunction === 'string') {
      this.log(AdaptedLogLevel.DEBUG, debugFunction)
    } else if (debugFunction) {
      this.log(AdaptedLogLevel.DEBUG, debugFunction())
    } else {
      this.log(AdaptedLogLevel.DEBUG, '' + debugFunction)
    }
  }

  important (msg) {
    this.log(AdaptedLogLevel.IMPORTANT, msg)
  }

  setLogLevel (level) {
    this.level = level
  }

  getLogLevel () {
    return this.level
  }

  log (type, msg) {
    if (type < this.level) {
      return
    }

    const timestamp = new Date().toISOString()
    const logMessage = `${this.name} - ${timestamp} - ${msg}`

    switch (type) {
      case AdaptedLogLevel.ERROR:
        logger.error(logMessage)
        break
      case AdaptedLogLevel.WARN:
        logger.warn(logMessage)
        break
      case AdaptedLogLevel.DEBUG:
        logger.log(logMessage.replace(/\\r\\n/g, '\n'))
        break
      case AdaptedLogLevel.INFO:
      case AdaptedLogLevel.IMPORTANT:
        logger.log(logMessage)
        break
      case AdaptedLogLevel.FATAL:
        logger.fatal(logMessage)
        break
    }
  }
}
