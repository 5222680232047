import axios from './axios'

function start ({ meetingId, userId }) {
  return axios.recording.start({ meetingId, presenterUuid: userId })
}

function stop ({ meetingId, userId }) {
  return axios.recording.stop({ meetingId, presenterUuid: userId })
}

export default {
  start,
  stop
}
