import { services } from '../http'

const API_LOGS_URL = process.env.VUE_APP_API_LOGS_URL
const API_LOGS_KEY = process.env.VUE_APP_API_LOGS_KEY

const post = (data) => {
  const headers = {
    'Authorization': `Basic ${API_LOGS_KEY}`,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
  const host = window.Unity ? 'nativeapp' : 'webapp'
  const params = `hostname=` + host + `&now=${Date.now()}`

  return services(API_LOGS_URL)
    .post(
      `ingest?${params}`,
      data,
      { headers }
    )
    .then(response => response.data)
}

export default post
