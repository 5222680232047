import axios from 'axios'

const http = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 1000000
})

export const services = url =>
  axios.create({
    baseURL: url,
    timeout: 2000000,
    trailingSlash: false
  })

export default http
