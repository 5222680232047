<template>
  <div class="video-tile">
    <video ref="videoElement" :style="getVideoStyle" class="tile" muted />
  </div>
</template>

<script>
import MeetingSpectatorService from '../services/MeetingSpectatorService'

export const FIT_MODES = {
  COVER: 'cover',
  CONTAIN: 'contain'
}

export default {
  name: 'VideoTile',
  props: {
    tileId: {
      type: Number,
      default: null
    },
    fitMode: {
      type: String,
      default: FIT_MODES.CONTAIN,
      validator (value) {
        return Object.values(FIT_MODES).includes(value)
      }
    }
  },
  computed: {
    getVideoStyle () {
      return {
        objectFit: this.fitMode
      }
    }
  },
  methods: {
    bindVideo (tileId = this.tileId) {
      if (MeetingSpectatorService.audioVideo) {
        MeetingSpectatorService.audioVideo.bindVideoElement(tileId, this.$refs.videoElement)
      }
    },
    unbindVideo (tileId = this.tileId) {
      if (MeetingSpectatorService.audioVideo) {
        MeetingSpectatorService.audioVideo.unbindVideoElement(tileId)
      }
    }
  },
  mounted () {
    if (this.tileId) {
      this.bindVideo()
    }
  },
  beforeDestroy () {
    if (this.tileId) {
      this.unbindVideo()
    }
  },
  watch: {
    tileId (newTileId, oldTileId) {
      if (oldTileId) {
        this.unbindVideo(oldTileId)
      }

      if (newTileId) {
        this.bindVideo(newTileId)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.video-tile
  position: relative
  width: 100%
  height: 100%

  .tile
    width: 100%
    height: 100%
    display: block

</style>
