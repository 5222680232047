export function mountUrl (baseUrl, path, params = {}) {
  const url = new URL(`${baseUrl}/${path}`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return url
}

export async function requestResource (url, method = 'GET') {
  const response = await fetch(url, { method })
  return response.json()
}

export function doResourceRequest (path, { method = 'GET', params = {}, baseUrl = process.env.VUE_APP_CHIME_LAMBDA_BASE_URL }) {
  return requestResource(mountUrl(baseUrl, path, params), method)
}
