import { doResourceRequest } from '../helpers/ResourceHelpers'

function join ({ title, name, role, userId }) {
  return doResourceRequest('join', {
    params: { title, name, role, userId },
    method: 'POST'
  })
}

function end ({ title }) {
  return doResourceRequest('end', {
    params: { title },
    method: 'POST'
  })
}

function attendee ({ title, attendeeId }) {
  return doResourceRequest('attendee', {
    params: {
      title,
      attendee: attendeeId
    }
  })
}

function getAttendee ({ chimeAttendeeId, externalUserId }) {
  return doResourceRequest('attendee', {
    params: {
      chimeAttendeeId,
      attendee: externalUserId
    }
  })
}

export default {
  join,
  end,
  attendee,
  getAttendee
}
