import {
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultModality,
  LogLevel,
  MeetingSessionConfiguration,
  MeetingSessionStatusCode
} from 'amazon-chime-sdk-js'

import { MEETING_STATE, MEETING_FAILED_REASONS } from '../enums/MeetingState'
import CONNECTION from '../enums/ConnectionQualityStatus'

import MeetingLoggerAdapter from './MeetingLoggerAdapter'

import ObserverHelper from '../helpers/ObserverHelper'

import MeetingResource from '../resources/MeetingResource'
import RecordingResource from '../resources/RecordingResource'

class MeetingSpectatorService {
  static instance = null

  static RECORDING_ATTENDEE_ID_SUFFIX = '-recording' // Recording tab stream attendee suffix
  static RECORDER_ATTENDEE_ID_SUFFIX = '-recorder' // Recorder server suffix
  static WAIT_FOR_ATTENDEE_TIMEOUT = 5000
  constructor () {
    if (MeetingSpectatorService.instance) {
      return MeetingSpectatorService.instance
    }

    MeetingSpectatorService.instance = this

    this.logger = new MeetingLoggerAdapter('CHIME RECORDER', LogLevel.WARN)

    // Chime Services/Controllers/Facades
    this.audioVideo = null
    this.meetingSession = null

    // State info
    this.meetingState = MEETING_STATE.DISCONNECTED
    this.meetingStateReason = null
    this.connectionQualityState = CONNECTION.STATUS.NOT_AVAILABLE

    // Active session data
    this.roster = {}
    this.rosterTileMap = {}
    this.configuration = null

    // Observers
    this.activeVideoTileObserver = new ObserverHelper(500)
    this.subscribeToActiveVideoTile = this.activeVideoTileObserver.subscribe
    this.unsubscribeFromActiveVideoTile = this.activeVideoTileObserver.unsubscribe

    this.meetingStateObserver = new ObserverHelper()
    this.subscribeToMeetingState = this.meetingStateObserver.subscribe
    this.unsubscribeFromMeetingState = this.meetingStateObserver.unsubscribe

    this.connectionObserver = new ObserverHelper()
    this.subscribeToConnectionQualityUpdate = this.connectionObserver.subscribe
    this.unsubscribeFromConnectionQualityUpdate = this.connectionObserver.unsubscribe

    // Resetter
    this.initializeMeetingService = () => {
      this.audioVideo = null
      this.meetingSession = null
      this.roster = {}
      this.rosterTileMap = {}
      this.configuration = null
      this.publishActiveTileUpdate()
      this.updateMeetingState(MEETING_STATE.DISCONNECTED)
      this.updateConnectionQualityState(CONNECTION.STATUS.NOT_AVAILABLE)
    }

    return MeetingSpectatorService.instance
  }

  async startSpectator ({ name, title, userId, region, audioElement }) {
    const recorderUserId = userId + MeetingSpectatorService.RECORDER_ATTENDEE_ID_SUFFIX

    try {
      this.updateMeetingState(MEETING_STATE.CONNECTING)

      const response = await MeetingResource.join({ title, region, name, role: 'recorder', userId: recorderUserId })

      if (response.error) {
        const message = `Chime server error on start recording: ${response.statusCode}. Message: ${response.message}.`
        const error = new Error(message)

        this.logger.error(message)
        throw error
      }

      const { JoinInfo } = response

      if (!JoinInfo) {
        const message = `Chime createRoom error: Failed to create room`
        this.logger.error(message)
        throw new Error(message)
      }

      this.name = name

      this.configuration = new MeetingSessionConfiguration(
        JoinInfo.Meeting,
        JoinInfo.Attendee
      )

      this.deviceController = new DefaultDeviceController(this.logger, { enableWebAudio: true })

      this.meetingSession = new DefaultMeetingSession(
        this.configuration,
        this.logger,
        this.deviceController
      )

      this.audioElement = audioElement
      this.audioVideo = this.meetingSession.audioVideo

      // This app can have audio muted due to Audio/Video autoplay block.
      // Recording server will not suffer from this because, as you can see in
      // the link bellow, the run.sh script requests the browser to disable
      // this feature (accessed at 2021-05-10).
      // https://github.com/aws-samples/amazon-chime-sdk-recording-demo/blob/be6e75b6a6397cfc485dfd45f6ca0842f5ac4421/recording/run.sh#L42
      this.audioVideo.bindAudioElement(this.audioElement)

      this.registerAudioVideoListeners()

      // State will be updated using AudioVideo listeners
      return this.audioVideo.start()
    } catch (error) {
      this.logger.fatal(`Failed to start spectator session: ${error.name} - ${error.message}`)
      this.stopSpectator()
      this.updateMeetingState(MEETING_STATE.FAILED, MEETING_FAILED_REASONS.UNKNOWN)
      throw error
    }
  }

  stopSpectator () {
    RecordingResource.stop({ meetingId: this.name }).catch(error => {
      this.logger.error(`Failed to call api to stop recording server: ${error.name} - ${error.message} - ${error}`)
    })
  }

  /**
   * ====================================================================
   * Listeners registry
   * ====================================================================
   */
  registerAudioVideoListeners () {
    this.audioVideoListeners = {
      audioVideoDidStart: this.onAudioVideoStart,
      audioVideoDidStop: this.onAudioVideoDidStop,
      videoTileDidUpdate: this.onVideoTileDidUpdate,
      videoTileWasRemoved: this.onVideoTileWasRemoved,
      connectionHealthDidChange: this.onConnectionHealthDidChange,
      audioVideoDidStartConnecting: this.onAudioVideoDidStartConnecting,
      connectionDidBecomeGood: this.onConnectionDidBecomeGood,
      connectionDidSuggestStopVideo: this.onConnectionDidBecomeBad,
      connectionDidBecomePoor: this.onConnectionDidBecomeBad
    }

    this.audioVideo.addObserver(this.audioVideoListeners)
    this.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.onAttendeePresenceChange)
  }

  unregisterAudioVideoListeners () {
    this.audioVideo.removeObserver(this.audioVideoListeners)
    this.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.onAttendeePresenceChange)
    this.audioVideoListeners = null
  }

  /**
   * ====================================================================
   * Internal Listeners
   * ====================================================================
   */
  onAudioVideoDidStartConnecting = (reconnecting) => {
    if (reconnecting) {
      this.updateConnectionQualityState(CONNECTION.STATUS.RECONNECTING)
    }
  }

  onAudioVideoStart = () => {
    this.updateMeetingState(MEETING_STATE.CONNECTED)
    this.updateConnectionQualityState(CONNECTION.STATUS.GOOD)
    setTimeout(this.onRecordingStopTimeout, MeetingSpectatorService.WAIT_FOR_ATTENDEE_TIMEOUT)
  }

  onAudioVideoDidStop = (status) => {
    const statusCode = status.statusCode()

    if (this.audioVideo) {
      this.unregisterAudioVideoListeners()
      this.initializeMeetingService()
    }

    this.updateMeetingState(MEETING_STATE.DISCONNECTED)
    this.updateConnectionQualityState(CONNECTION.STATUS.NOT_AVAILABLE)

    if (status.isFailure() || statusCode === MeetingSessionStatusCode.MeetingEnded) {
      const statusCodeName = Object.keys(MeetingSessionStatusCode).find(key => MeetingSessionStatusCode[key] === statusCode)
      this.logger.error(`Recording audio video stopped with status ${statusCode} - ${statusCodeName}`)
      this.stopSpectator()
    }
  }

  onConnectionHealthDidChange = (connectionHealth) => {
    if (connectionHealth.consecutiveStatsWithNoPackets >= this.SUCCESSIVE_NO_PACKETS_TO_DISCONNECT) {
      this.updateConnectionQualityState(CONNECTION.STATUS.DISCONNECTED)
    }
  }

  onConnectionDidBecomeGood = () => {
    this.updateConnectionQualityState(CONNECTION.STATUS.GOOD)
  }

  onConnectionDidBecomeBad = () => {
    this.updateConnectionQualityState(CONNECTION.STATUS.BAD)
  }

  onAttendeePresenceChange = (attendeeId = null, present = false, externalUserId = '') => {
    const isRecordingAttendee = this.isRecordingAttendee(externalUserId)
    const isExternalContentShare = (new DefaultModality(attendeeId)).hasModality(DefaultModality.MODALITY_CONTENT) && !isRecordingAttendee

    // Ignore attendee if not is recording or a content share
    if (!isRecordingAttendee && !isExternalContentShare) {
      return
    }

    if (!present) {
      if (isRecordingAttendee) {
        // Recording attendee left the room
        this.stopSpectator()
      }
      delete this.roster[attendeeId]
      return
    }

    if (!this.roster[attendeeId]) {
      this.roster[attendeeId] = {}
    }

    this.roster[attendeeId].attendeeId = attendeeId
    this.roster[attendeeId].externalUserId = externalUserId

    this.publishActiveTileUpdate()
  }

  onVideoTileDidUpdate = (tileState) => {
    const attendeeId = tileState?.boundAttendeeId

    if (!tileState?.tileId || !attendeeId) { return }

    this.rosterTileMap[attendeeId] = tileState
    this.publishActiveTileUpdate()
  }

  onVideoTileWasRemoved = (tileId) => {
    const attendeeId = Object.keys(this.rosterTileMap).find(attendeeId => tileId === this.rosterTileMap[attendeeId]?.tileId)

    if (attendeeId) {
      delete this.rosterTileMap[attendeeId]
      this.publishActiveTileUpdate()
    }
  }

  onRecordingStopTimeout = () => {
    const recordingAttendee = Object.keys(this.roster).find(attendeeId => this.isRecordingAttendee(this.roster[attendeeId]?.externalUserId))

    /*
     * If recording attendee is not present, stop recorder
     * This can occur if user loss his connection just after start a recording.
     */
    if (!recordingAttendee) {
      // TODO: Find some way to prevent file to be stored in S3 and api
      this.recordingStopTimeout = null
      this.stopSpectator()
    }
  }

  /**
   * ====================================================================
   * Update helpers
   * ====================================================================
   */
  updateMeetingState (newStatus, reason = null) {
    if (newStatus !== this.meetingState) {
      this.meetingState = newStatus

      if (reason) {
        this.meetingStateReason = reason
      }

      this.logger.important(`Recording state did change. New state: ${this.meetingState}. ${reason ? `Reason: ${reason}.` : ''}`)
      this.meetingStateObserver.publish(this.meetingState)
    }
  }

  updateConnectionQualityState (newState) {
    if (newState !== this.connectionQualityState) {
      this.connectionQualityState = newState
      this.connectionObserver.publish(this.connectionQualityState)
    }
  }

  /**
   * ====================================================================
   * Utilities
   * ====================================================================
   */
  publishActiveTileUpdate () {
    const activeTiles = []

    // Prioritizes users content shares on top of recording content share
    Object.keys(this.roster).forEach(attendeeId => {
      if (!this.rosterTileMap[attendeeId]?.tileId) {
        return
      }

      if (this.isRecorderAttendee(this.roster[attendeeId]?.externalUserId ?? '')) {
        activeTiles.push(this.rosterTileMap[attendeeId].tileId)
        return
      }

      if ((new DefaultModality(attendeeId)).hasModality(DefaultModality.MODALITY_CONTENT)) {
        activeTiles.unshift(this.rosterTileMap[attendeeId].tileId)
      }
    })

    this.activeVideoTileObserver.publish(activeTiles)
  }

  /**
   * ====================================================================
   * Helpers
   * ====================================================================
   */
  hasRecordingModality (userId) {
    return this.isRecordingAttendee(userId) || this.isRecorderAttendee(userId)
  }

  isRecordingAttendee (userId) {
    return userId.endsWith(MeetingSpectatorService.RECORDING_ATTENDEE_ID_SUFFIX)
  }

  isRecorderAttendee (userId) {
    return userId.endsWith(MeetingSpectatorService.RECORDER_ATTENDEE_ID_SUFFIX)
  }

  /**
   * ====================================================================
   * Getters
   * ====================================================================
   */
  get isConnected () {
    return this.meetingState === MEETING_STATE.CONNECTED
  }

  get isConnecting () {
    return this.meetingState === MEETING_STATE.CONNECTING
  }

  get isInitializing () {
    return this.meetingState === MEETING_STATE.INITIALIZING
  }

  get isConnectionGood () {
    return this.connectionQualityState === CONNECTION.STATUS.GOOD
  }

  get isConnectionBad () {
    return this.connectionQualityState === CONNECTION.STATUS.BAD
  }

  get isConnectionPoor () {
    return this.connectionQualityState === CONNECTION.STATUS.POOR
  }

  get recorderAttendeeId () {
    return this.meetingSession?.configuration?.credentials?.attendeeId
  }
}

export default new MeetingSpectatorService()